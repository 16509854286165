import axios, { AxiosInstance } from 'axios'

const getHeaders: (
  headers?:
    | {
        id: string
        value: string
      }[]
    | undefined,
) => {
  [key: string]: string
} = (headers?: { id: string; value: string }[]) => {
  const defaultHeader: { [key: string]: string } = {}

  headers?.map(
    (item: { id: string; value: string }) =>
      (defaultHeader[item.id] = item.value),
  )

  return defaultHeader
}

const getHostname: () => string | undefined = () => {
  return process.env.REACT_APP_BACKEND_DOMAIN
  // if (window.location.hostname === process.env.REACT_APP_WEBSITE_DOMAIN) {
  //   return process.env.REACT_APP_BACKEND_DOMAIN
  // }
  //
  // // if (window.location.hostname === 'localhost') {
  // //   return window.location.hostname
  // // }
  //
  // return 'api.' + window.location.hostname
}

const api: AxiosInstance = axios.create({
  baseURL:
    // window.location.protocol +
    // '//' +
    getHostname(),
  // (window.location.port === '3000' ? ':8000' : ''),
})

//eslint-disable-next-line
export const post: (
  url: string,
  data: any,
  config?: { headers: { id: string; value: string }[] }, // eslint-disable-next-line
) => any = (
  url: string,
  data: any,
  config?: { headers: { id: string; value: string }[] },
) => {
  return api.post(url, data, { headers: getHeaders(config?.headers) })
}

//eslint-disable-next-line
export const get: (
  url: string,
  config?: { headers: { id: string; value: string }[] }, // eslint-disable-next-line
) => any = (
  url: string,
  config?: { headers: { id: string; value: string }[] },
) => {
  return api.get(url, { headers: getHeaders(config?.headers) })
}

export default api
