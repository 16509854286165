import { FormValues } from '@Types/FormDataTypes'
import { createContext } from 'react'
import { SelectCardOptionType } from '@Types/FieldTypes'

export type CreditCardFlowStepsContextType = {
  displaySummaryData: boolean
  setDisplaySummaryData: React.Dispatch<React.SetStateAction<boolean>>
  isPrecalified: boolean
  setIsPrecalified: React.Dispatch<React.SetStateAction<boolean>>
  formValues: FormValues
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>
  tokenForm: string
  setTokenForm: React.Dispatch<React.SetStateAction<string>>
  productsSelected: SelectCardOptionType[]
  setProductsSelected: React.Dispatch<
    React.SetStateAction<SelectCardOptionType[]>
  >
}

const CreditCardFlowStepsContext = createContext({
  displaySummaryData: false, // eslint-disable-next-line
  setDisplaySummaryData: () => {},
  isPrecalified: false, // eslint-disable-next-line
  setIsPrecalified: () => {},
  formValues: {}, // eslint-disable-next-line
  setFormValues: () => {},
  tokenForm: '', // eslint-disable-next-line
  setTokenForm: () => {},
  productsSelected: [], // eslint-disable-next-line
  setProductsSelected: () => {},
} as CreditCardFlowStepsContextType)

export default CreditCardFlowStepsContext
