import { APIError } from './ErrorInterpreter'
import { getCredentials, ApiCredentials } from './loadDataFunctions'

const ERROR_136 = 136
const H24 = 24
const M60 = 60
const S1000 = 1000

export const setCookie: (
  cookieName: string,
  cookieValue: string,
  cookieDays?: number,
) => void = (cookieName: string, cookieValue: string, cookieDays?: number) => {
  let timeExpires = ''
  if (cookieDays) {
    const cookieDate: Date = new Date()
    cookieDate.setTime(
      cookieDate.getTime() + cookieDays * H24 * M60 * M60 * S1000,
    )
    timeExpires = '; expires=' + cookieDate.toUTCString()
  }
  document.cookie =
    cookieName + '=' + (cookieValue || '') + timeExpires + '; path=/'
}

export const getCookie: (cookieName: string) => string | null = (
  cookieName: string,
) => {
  const cookieSavedName = cookieName + '='
  const cookiesList: string[] = document.cookie.split(';')

  for (let index = 0; index < cookiesList.length; index++) {
    let cookieSelected: string = cookiesList[index]
    while (cookieSelected.charAt(0) === ' ') {
      cookieSelected = cookieSelected.substring(1, cookieSelected.length)
    }
    if (cookieSelected.indexOf(cookieSavedName) === 0) {
      return cookieSelected.substring(
        cookieSavedName.length,
        cookieSelected.length,
      )
    }
  }
  return null
}

export const eraseCookie: (cookieName: string) => void = (
  cookieName: string,
) => {
  document.cookie =
    cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

//eslint-disable-next-line
export const loadTokenIfExpired: (errorLoad: any) => void = (
  errorLoad: any,
) => {
  if (
    errorLoad.response &&
    errorLoad.response.data.errors.some(
      (errorItem: APIError) => errorItem.code === ERROR_136,
    )
  ) {
    getCredentials().then((response: ApiCredentials) =>
      setCookie('api-token', response.data.access),
    )
  }
}
