import i18n from '@I18nConfig'
import { ErrorGrid, ErrorText } from '@StyledComponents/Error/Error'
import React from 'react'

const Error404Subtitle = () => {
  return (
    <ErrorGrid item lg={3} md={3} sm={6} xs={6}>
      <ErrorText className="subtitle">{i18n.t('error404.subtitle')}</ErrorText>
    </ErrorGrid>
  )
}

export default Error404Subtitle
