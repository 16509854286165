import commonEs from '@Locales/es_translation'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
  fallbackLng: 'es',
  debug: true,
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
  resources: {
    es: {
      translation: commonEs,
    },
  },
})

export default i18n
