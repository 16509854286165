import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

interface RedirectToNewLocationProps {
  newRoute: string
}

const RedirectToNewLocation: React.FC<RedirectToNewLocationProps> = ({
  newRoute,
}) => {
  const location = useLocation()

  // Get all parameters from the original URL
  const queryParams = new URLSearchParams(location.search)

  // Convert parameters into an object for future reference
  const queryParamsObject: Record<string, string> = {}
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value
  })

  // Redirect to the new location while keeping all parameters
  return (
    <Redirect
      to={{
        pathname: newRoute,
        search: location.search,
        state: { queryParams: queryParamsObject },
      }}
    />
  )
}

export default RedirectToNewLocation
