import { Error404Container } from '@StyledComponents/Error/Error404/Error404'
import React, { useEffect } from 'react'
import Error404Button from './Error404Button'
import Error404Image from './Error404Image'
import Error404Subtitle from './Error404Subtitle'
import Error404Title from './Error404Title'

export const Error404 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Error404Container container justify="center" alignContent="center">
      <Error404Image />
      <Error404Title />
      <Error404Subtitle />
      <Error404Button />
    </Error404Container>
  )
}
