import i18n from '@I18nConfig'
import { ErrorButton, ErrorGrid } from '@StyledComponents/Error/Error'
import { CustomLink } from '@StyledComponents/CreditCardsHome/Home'
import React from 'react'

const Error404Button = () => {
  return (
    <ErrorGrid item xs={12} className="button">
      <CustomLink to="/">
        <ErrorButton>{i18n.t('error404.button')}</ErrorButton>
      </CustomLink>
    </ErrorGrid>
  )
}

export default Error404Button
