import { Button, Grid, Typography } from '@material-ui/core'
import { colors, fonts } from '@StyledComponents/theme'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HomeContainer = styled(Grid)`
  background-repeat: no-repeat;
  background-size: 60vw 500px;
  background-position: right top;
`

export const BottomContainer = styled(Grid)``

export const TextTitle = styled(Typography)`
  font-family: ${fonts.primaryMedium};
  text-align: center;
  line-height: 1.2em;

  &.title {
    color: ${colors.primary};
    font-size: 45px;
    line-height: 55px;
  }

  @media (max-width: 959px) {
    &.only-mobile {
      display: unset;
    }
    &.only-desktop {
      display: none;
    }
  }
  @media (min-width: 960px) {
    &.only-mobile {
      display: none;
    }
    &.only-desktop {
      display: unset;
    }
  }

  &.title-home {
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    font-size: 55px;
    text-align: left;
    line-height: 55px;
  }

  &.subtitle {
    color: ${colors.darkText};
  }

  &.title-steps {
    font-size: 45px;
    color: ${colors.primary};
  }

  &.subtitle-home {
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: left;
  }

  &.title-short {
    color: ${colors.primary};
    font-size: 30px;
    text-align: center;
  }

  &.subtitle-short {
    color: ${colors.darkText};
    font-size: 22px;
    font-weight: unset;
    text-align: center;
  }

  &.description {
    color: ${colors.darkText};
    font-size: 14px;
    font-weight: unset;
    text-align: center;
  }

  &.semibold {
    font-weight: 500;
  }

  &.mt {
    margin-top: 60px;
  }

  @media (max-width: 599px) {
    &.title-short {
      text-align: center;
      font-size: 20px;
    }
    &.subtitle-short {
      text-align: center;
      font-size: 15px;
    }

    &.title-home {
      line-height: 1.2em;
    }
  }

  @media (max-width: 599px) {
    &.subtitle-short {
      text-align: center;
      font-size: 18px;
    }
  }

  &.title-short-second {
    color: ${colors.primary};
    font-size: 24px;
  }

  &.title-bottom {
    color: white;
    text-align: left;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
  }

  &.title-large {
    color: white;
    font-size: 35px;
    text-align: left;
  }

  @media (max-width: 959px) {
    &.title {
      font-size: 26px;
    }
  }

  @media (max-width: 599px) {
    &.title-home {
      text-align: center;
      font-size: 35px;
    }

    &.subtitle-home {
      text-align: center;
      font-size: 18px;
    }

    &.title-steps {
      font-size: 30px;
    }
    &.mt {
      margin-top: 30px;
    }
    &.title-large {
      font-size: 30px;
      text-align: center;
    }

    &.title-bottom {
      text-align: center;
    }
  }
`

export const StepsTitleContainer = styled(Grid)`
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 959px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

export const TitleSection = styled(Typography)`
  font-family: ${fonts.primaryRegular};
  text-align: center;
  line-height: 1.2em;

  &.subtitle {
    font-weight: bold;
    color: ${colors.homeSubtitle};
  }

  &.title {
    color: ${colors.primary};
  }

  &.image-section-title {
    color: ${colors.homeSubtitle};
    text-align: left;
    font-weight: bold;
    font-size: 43px;
  }

  &.image-section-subtitle {
    color: ${colors.homeSubtitle};
    text-align: left;
    padding-top: 30px;
  }

  &.title-icons {
    color: ${colors.primary};
    font-size: 20px;
    font-weight: normal;
  }

  @media (max-width: 959px) {
    &.image-section-title {
      font-size: 28px;
      text-align: center;
    }

    &.image-section-subtitle {
      text-align: center;
    }
  }
`

export const CustomLink = styled(Link)`
  text-decoration: none;
  width: 50%;

  &.home {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 959px) {
    margin: auto;
    width: 100%;
  }
`

export const HomeButton = styled(Button)`
  background-color: ${colors.primary};
  border-color: ${colors.primary};
  border-radius: 5px;
  color: white;
  font-family: ${fonts.primaryRegular};

  .MuiButton-label {
    padding: 0px;
    font-size: 16px;
  }

  &.home {
    width: 100%;
    text-transform: none;
  }

  &.header-button {
    .MuiButton-label {
      padding: 0px;
    }

    box-shadow: none;
  }

  &.bottom {
    background-color: transparent;
    border: 1px solid white;
  }

  &.bottom:hover {
    background-color: white;
    color: #333;
    border: 1px solid #333;
  }

  &:hover {
    background-color: ${colors.soap};
  }

  &.noshadow {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    .MuiButton-label {
      font-size: 17px;
      margin: auto;
    }
  }

  @media (max-width: 950px) {
    &.home {
      .MuiButton-label {
        margin: 5px 30px 5px 30px;
        font-size: 14px;
      }

      &.first {
        .MuiButton-label {
          margin: 5px 25px 5px 25px;
        }
      }
      width: 100%;
    }
  }
`
