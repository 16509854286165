const esTranslation = {
  home: {
    requestCard: {
      title: 'SOLICITA UNA TARJETA DE CRÉDITO',
      subtitle: 'En solo 3 pasos',
      data: {
        titles: {
          analyze: 'Completa el formulario',
          card: 'Te presentamos las tarjetas que más se adaptan a ti',
          courier:
            'Si tu tarjeta es aprobada, la recibirás en el transcurso de 72 horas',
        },
        descriptions: {
          analyze:
            'Proporciona tus datos de manera rápida y segura, en solo 3 pasos.',
          card: 'Selecciona entre ellas la tarjeta de tu preferencia.',
          courier:
            'Si tu tarjeta es aprobada, llegará a la dirección proporcionada.',
        },
      },
    },
    cardList: {
      title: 'LAS TARJETAS DE MÁS PRESTIGIO DEL PAÍS',
      subtitle: '50 años de confianza',
    },
    threeSteps: 'En solo 3 pasos',
    topSection: {
      title: 'Tenemos la tarjeta para ti',
      description:
        'Solicítala y recibe una devolución de $20 por tu primer consumo de igual o mayor valor.*',
      button: 'QUIERO MI TARJETA',
      terms:
        '*Aplican condiciones. La devolución aplica en consumos acumulados durante el primer mes a partir de la fecha de entrega de la tarjeta. Se verá reflejada como nota de crédito en tu estado de cuenta hasta en 60 días posteriores al finalizar el período de consumo.',
    },
    stepsSection: {
      first: {
        title: 'Ingresa tu cédula o pasaporte',
        description: 'Regístrate de manera rápida y segura.',
      },
      second: {
        title: 'Proporciona tus datos personales',
        description: 'Te ofreceremos las tarjetas que más se adaptan a ti.',
        button: 'QUIERO MI TARJETA',
      },
      third: {
        title: 'Elige una tarjeta',
        description:
          'Si tu tarjeta es aprobada, la recibirás en 72 horas o menos.',
      },
    },
    buttonSection: {
      title: 'Todo en una sola tarjeta',
      subtitle: '¿Quieres acceder a los beneficios?',
      button: 'QUIERO MI TARJETA',
    },
    iconSection: {
      title: 'A través de las tarjetas accederás a',
      elements: {
        cup: {
          title: 'Planes de recompensas',
          description: 'Acumula millas o Cashback por tus consumos.',
        },
        star: {
          title: 'Beneficios',
          description: 'Disfruta de promociones en establecimientos afiliados.',
        },
        shield: {
          title: 'Seguros y asistencias',
          description:
            'Accede a seguros y asistencias a nivel nacional e internacional.',
        },
      },
    },
  },
  modalLoading: 'Cargando, espera por favor...',
  modalCrossel: 'Serás redirigido en unos instantes...',
  modalButtonConfirm: 'Confirmar',
  modalButtonCancel: 'Cancelar',
  header: {
    needHelp: '¿Necesitas ayuda?',
    phone: '(02) 2984 400',
  },
  footer: {
    needHelp: '¿Necesitas ayuda?',
    phone: '(02) 2984 400',
    copyrightLogo: 'Copyright © ',
    copyright: ' Diners Club Ecuador. ',
    registered: 'Derechos reservados.',
    textImage: 'Tu mundo sin límites',
    follow: 'Síguenos en:',
    warning: 'Aviso de la Política de privacidad',
    advance: 'Consejos de seguridad',
    term: 'Términos y condiciones',
  },
  form: {
    nextButton: 'Siguiente',
    securityText:
      'En Diners Club del Ecuador S.A. utilizamos la última tecnología para mantener todos tus datos protegidos',
    errorText: 'Por favor, completa los campos obligatorios.',
    requiredField: 'Ingresa un valor.',
    requiredNames: 'Ingresa tus nombres.',
    permitOnlyCharacters: 'Ingresa únicamente caracteres.',
    requiredSurnames: 'Ingresa tus apellidos.',
    requiredPhone: 'Ingresa tu número celular.',
    invalidPhone: 'Ingresa un celular válido.',
    requiredEmail: 'Ingresa tu correo electrónico.',
    requiredRepeatEmail: 'Ingresa tu correo electrónico nuevamente.',
    emailsNotMatch: 'Los correos electrónicos no conciden.',
    invalidEmail: 'Ingresa un correo electrónico válido.',
    requiredIdCard: 'Ingresa tu número de cédula.',
    invalidIdCard: 'Ingresa el número de cédula correcto.',
    requiredFingerCode: 'Ingresa los 6 primeros dígitos de tu código dactilar.',
    invalidFingerCode: 'Ingresa un código dactilar válido de 6 dígitos.',
    requiredPassport: 'Ingresa tu pasaporte.',
    invalidFormat: 'Este campo ha de tener el formato adecuado',
    requiredTerms: 'Debes aceptar los términos y condiciones para continuar.',
    requiredHomeCity: 'Ingresa tu ciudad.',
    requiredHomeDistrict: 'Ingresa el sector de tu vivienda.',
    requiredStreet: 'Ingresa una calle principal.',
    requiredStreetSecondary: 'Ingresa una calle secundaria.',
    requiredHomeNumber: 'Ingresa el número de la dirección de tu domicilio.',
    requiredSelectName: 'Es necesario seleccionar un nombre',
    requiredLocationRef: 'Ingresa una referencia.',
    requiredIncomes: 'Ingresa un monto de ingresos',
    limitMoney: 'Has excedido el monto máximo de USD',
    requiredAccountNumber: 'Ingresa tu número de cuenta.',
    requiredFile: 'Carga al menos un archivo.',
    invalidFileFormat: 'Únicamente se permiten archivos en formato PDF o JPG',
    limitFiles: 'Carga hasta máximo ',
    files: ' archivos.',
    limitFileSize: 'Se ha excedido el tamaño de ',
    requiredCompany: 'Ingresa el nombre de la empresa.',
    requiredCompanyPhone: 'Ingresa tu número de teléfono de oficina.',
    requiredCompanyNumber: 'Ingresa el número de la dirección de tu oficina.',
    minLengthField: 'Ingresa al menos ',
    maxLengthField: 'Ingresa hasta ',
    characters: ' caracteres.',
    limitNumber: ' Este campo no puede exceder de ',
    selectOneOption: 'Selecciona una opción.',
    selectMinOneOption: 'Selecciona al menos una opción.',
    fileListEmpty: 'No has seleccionado ningún archivo',
    loadingMap: 'Cargando mapa',
  },
  steps: {
    section1: {
      caption: 'Sección 1',
      label: 'Datos de Contacto',
    },
    section2: {
      caption: 'Sección 2',
      label: 'Informacion Personal',
    },
    section3: {
      caption: 'Sección 3',
      label: 'Finalizar Registro',
    },
    completed: 'Completado',
  },
  stepHeaders: {
    title: {
      variant1: 'Estás a pocos minutos de crear tu mundo sin límites',
      variant2: ', estás a pocos minutos de crear tu mundo sin límites',
      variant3: '',
    },
    description: {
      variant1:
        'Disfruta de grandes beneficios con Diners Club, TITANIUM y Discover.',
      variant2: 'Tenemos una selección de tarjetas para ti',
      variant3:
        ', disfruta de los Beneficios de Ser Socio y vive tu mundo sin límites',
      variant4:
        ', tus Tarjetas Diners Club, TITANIUM y Discover esperan por ti.',
    },
    secureConnection: 'Conexión segura',
    cardCaption: '',
    cardCaptionFeidIn: 'Puedes Seleccionar más de una',
  },
  formFirstStep: {
    title: 'Datos de contacto',
    subtitle: 'Los usaremos sólo para identificarte y comunicarnos contigo:',
    fields: {
      names: {
        label: 'Nombre(s) como constan en tu cédula',
        placeholder: 'Ingresa tu  nombre',
      },
      surnames: {
        label: 'Apellido(s) como constan en tu cédula',
        placeholder: 'Ingresa tus apellidos',
      },
      phone: {
        label: 'Celular',
        placeholder: 'Ingresa tu número de celular',
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Ingresa tu correo electrónico',
      },
      repeatEmail: {
        label: 'Repite tu correo electrónico',
        placeholder: 'Ingresa tu correo electrónico nuevamente',
      },
      identificationCard: {
        label: 'Número de identificación',
        placeholder: 'Ingresa tu número de cédula',
      },
      identificationType: {
        label: 'Tipo de identificación',
      },
      fingerCode: {
        label: '6 primeros dígitos del código dactilar',
        placeholder: 'Ingresa el código dactilar de tu cédula',
        infoText:
          'Encontrarás este código de 10 dígitos en la parte superior de la huella dactilar en tu cédula',
      },
      passport: {
        label: 'Número de pasaporte',
        placeholder: 'Ingresa tu número de pasaporte',
      },
      city: {
        label: 'Ciudad',
        placeholder: 'Quito',
      },
      termCond: {
        label: 'Aceptar términos y condiciones / Protección de datos',
        textBox: {
          titleFirst: 'PERSONA NATURAL:',
          titleSecond: 'Términos y Condiciones',
          firstText: '',
          secondText:
            'Conozco y entiendo que los cargos o costos que se detallan en este portal por los servicios financieros prestados, son los valores autorizados por la Junta de Política y Regulación Financiera y serán cargados, por la entidad financiera; en función de los servicios financieros solicitados.\n' +
            '\n' +
            'Conozco y acepto que al concluir esta solicitud y al registrar mis datos en este portal, la Institución Financiera puede aprobar, negar la solicitud presentada o inclusive puede solicitar información adicional, para lo cual puedo ser contactado por un asesor de Banco Diners Club del Ecuador S.A., para gestionar mi solicitud.\n' +
            '\n' +
            'Conozco y acepto que Banco Diners Club del Ecuador S.A., podrá solicitar la validación de identidad por los medios que considere necesarios y de los datos que hayan sido previamente registrados, entre ellos, podrá utilizar mi imagen personal, datos biométricos y otros que considere apropiados para este fin. En el caso que no se genere dicha validación de manera exitosa, el proceso de solicitud y aprobación del servicio financiero no podrá ser concluido.\n' +
            '\n' +
            'Certifico que los fondos con los cuales opero, de ser el caso, provienen de una fuente lícita y no se destinarán a actividades relacionadas con la producción, consumo, comercialización y tráfico de sustancias estupefacientes y psicotrópicas o cualquier otra actividad relacionada al Lavado de Activos y el Financiamiento de Delitos.\n' +
            '\n' +
            'En el caso de presentar la condición no residente en el Ecuador, emito mi autorización expresa para la entrega de información financiera al Servicio de Rentas Internas, en función de la legislación vigente.\n' +
            '\n' +
            '\n',
          titleThird: 'PROTECCIÓN DE DATOS PERSONALES',
          thirdText:
            '\n' +
            '\n' +
            'En el desarrollo legítimo de la relación comercial, durante su vigencia, mediante este medio conozco y emito mi consentimiento unilateral y expreso de manera libre y voluntaria del tratamiento de mis datos personales, entendido como: recolección a través de sus canales electrónicos o cualquier medio, almacenamiento, procesamiento, transmisión, eliminación y cualquier otro tratamiento o conjunto de tratamientos, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizados por el Banco, con las finalidades de: identificarme, actualizar mis datos, ofrecerme los servicios y productos a través de los canales definidos por el Banco, realizar validaciones de la veracidad de mi información consignada,  para la verificación de mi comportamiento crediticio, dentro de lo cual pueda obtener de cualquier fuente de información, referencias relativas a mis datos consignados y en general cualquier información relevante para conocer mi comportamiento crediticio, incluido Burós de información crediticia autorizados para operar en el país, Registro de Datos Crediticios, Sistema Nacional de Registros Públicos, Dirección General de Registro Civil, y otros que determine la normativa vigente, incluso respecto mi desempeño como deudor, manejo de mi(s) cuenta(s) corrientes(s), de ahorro, tarjetas de crédito, u otros productos financieros, cumplimiento de mi(s) obligación(es) y demás activos, pasivos, datos personales y/o patrimoniales en entidades públicas y privadas, anterior(es), actual(es) y/o posterior(es) al registro de mis datos en los diversos canales del Banco. \n' +
            '\n' +
            'Autorizo a Banco Diners Club del Ecuador S.A. a acceder a mis datos registrados en la Dirección General de Registro Civil, Identificación y Cedulación, para validar los datos que he proporcionado en este portal, y luego de mi aceptación sean registrados, para el desarrollo legítimo de la relación comercial.\n' +
            '\n' +
            'Autorizo de manera expresa para que el Banco pueda utilizar y entregar mi información y datos personales como datos de contacto para fines comerciales, publicitarios, análisis de datos o comunicacionales de productos o servicios propios o de sus aliados a través de los diferentes canales de comunicación.\n' +
            '\n' +
            'Autorizo y conozco que el Banco queda facultado para requerir, registrar, mantener y reportar la información y las transacciones señaladas en la “Ley de Prevención, Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos” y otras normativas aplicables.\n' +
            '\n' +
            'Adicionalmente, mediante la presente declaro conocer y aceptar el Aviso de Política de Privacidad de Banco Diners Club en la cual se indican mis derechos, datos tratados y las actividades comerciales de los aliados, así como otros datos de interés respecto a la protección de datos personales, que se encuentran detallados en el sitio web de Diners Club en la ruta https://www.dinersclub.com.ec/aviso-de-la-politica-de-privacidad, mediante la cual, podré solicitar la gestión de mis derechos o consentimientos.\n' +
            '\n' +
            'El Banco me notificará sobre las actualizaciones del aviso de política de privacidad antes mencionado, a través del sitio web de Diners o por medio de los canales digitales.',

          titleFourth: 'PERSONA JURIDICA:',
          titleFifth: 'Términos y Condiciones',
          fourthText: '',
          fifthText:
            '\n' +
            'Conozco y acepto que al concluir esta solicitud y al registrar los de mi representada en este portal, la Institución Financiera puede aprobar, negar la solicitud presentada o inclusive puede solicitar información adicional, para lo cual puedo ser contactado por un asesor de Banco Diners Club del Ecuador S.A., para gestionar esta solicitud.\n' +
            '\n' +
            'Conozco y acepto que Banco Diners Club del Ecuador S.A., podrá solicitar la validación de identidad por los medios que considere necesarios y de los datos del representante legal o firma autorizada, que hayan sido previamente registrados, entre ellos, podrá utilizar su imagen personal, datos biométricos y otros que considere apropiados para este fin. En el caso que no se genere dicha validación de manera exitosa, el proceso de solicitud y aprobación del servicio financiero no podrá ser concluido.\n' +
            '\n' +
            'Conozco y autorizo el tratamiento de datos de la persona jurídica para la verificación del comportamiento crediticio, dentro de lo cual pueda obtener de cualquier fuente de información, referencias relativas a datos consignados y en general cualquier información relevante para conocer el comportamiento crediticio, incluido Burós de información crediticia autorizados para operar en el país, Registro de Datos Crediticios, Sistema Nacional de Registros Públicos, Superintendencias, Servicio de Rentas Interna y otros que determine la normativa vigente, incluso respecto del desempeño como deudora, manejo de cuenta(s) corrientes(s), de ahorro, tarjetas de crédito, u otros productos financieros, cumplimiento de la (s) obligación(es) y demás activos, pasivos, patrimoniales en entidades públicas y privadas, anterior(es), actual(es) y/o posterior(es) a esta solicitud.\n' +
            '\n' +
            'Certifico que los fondos con los cuales opero, así como los de mi representada, provienen de una fuente lícita y no se destinarán a actividades relacionadas con la producción, consumo, comercialización y tráfico de sustancias estupefacientes y psicotrópicas o cualquier otra actividad relacionada al Lavado de Activos y el Financiamiento de Delitos.\n' +
            '\n' +
            'En el caso de que mi representada ostente la condición no residente en el Ecuador, emito mi autorización expresa para la entrega de información financiera al Servicio de Rentas Internas, en función de la legislación vigente.\n' +
            '\n',
          titleSix: 'PROTECCIÓN DE DATOS PERSONALES',
          SixText:
            '\n' +
            'En el desarrollo legítimo de la relación comercial, durante su vigencia, mediante este medio conozco y emito mi consentimiento unilateral y expreso de manera libre y voluntaria del tratamiento de mis datos personales como representante legal, entendido como: recolección a través de sus canales electrónicos o cualquier medio, almacenamiento, procesamiento, transmisión, eliminación y cualquier otro tratamiento o conjunto de tratamientos, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizados por el Banco, con las finalidades de: identificarme, actualizar mis datos, ofrecerme los servicios y productos a través de los canales definidos por el Banco, realizar validaciones de la veracidad de mi información consignada y para registrar, mantener y reportar la información y las transacciones señaladas en la “Ley de Prevención, Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos”.\n' +
            '\n' +
            'Autorizo a Banco Diners Club del Ecuador S.A. a acceder a mis datos personales registrados en mi condición de representante legal, en la Dirección General de Registro Civil, Identificación y Cedulación, para validar los datos que he proporcionado en este portal, y luego de mi aceptación sean registrados, para el desarrollo legítimo de la relación comercial.\n' +
            '\n' +
            'Autorizo de manera expresa para que el Banco pueda utilizar y entregar mi información y datos personales como datos de contacto para fines comerciales, publicitarios, análisis de datos o comunicacionales de productos o servicios propios o de sus aliados a través de los diferentes canales de comunicación.\n' +
            '\n' +
            'Adicionalmente, mediante la presente declaro conocer y aceptar el Aviso de Política de Privacidad de Banco Diners Club en la cual se indican mis derechos, datos tratados y las actividades comerciales de los aliados, así como otros datos de interés respecto a la protección de datos personales en mi calidad de representante legal, que se encuentran detallados en el sitio web de Diners Club en la ruta https://www.dinersclub.com.ec/aviso-de-la-politica-de-privacidad , mediante la cual, podré solicitar la gestión de mis derechos o consentimientos.\n' +
            '\n' +
            'El Banco me notificará sobre las actualizaciones del aviso de política de privacidad antes mencionado, a través del sitio web de Diners o por medio de los canales digitales.',
        },
      },
    },
    idCard: 'Cédula',
    passport: 'Pasaporte',
  },
  formSecondStep: {
    title: 'Cuéntanos sobre ti',
    subtitle: 'Por favor, ayúdanos con la siguiente información',
    sections: {
      address: {
        title: 'Dirección',
        subtitle: 'Indícanos donde quieres que enviemos tus tarjetas',
        fields: {
          homeCity: {
            label: 'Ciudad',
            placeholder: 'Selecciona tu ciudad',
          },
          homeDistrict: {
            label: 'Sector de vivienda',
            placeholder: 'Selecciona tu sector de residencia',
          },
          homeDistrictText: {
            placeholder: 'Ingresa tu sector de residencia',
          },
          mainStreet: {
            label: 'Calle principal',
            placeholder: 'Ingresa la calle principal',
          },
          number: {
            label: 'Número de domicilio',
            placeholder: 'Ingresa el número de tu domicilio',
          },
          secondStreet: {
            label: 'Calle secundaria',
            placeholder: 'Ingresa la calle secundaria',
          },
          homeMap: { label: 'Indica la ubicación de tu domicilio en el mapa' },
          locationRefHome: {
            selectorField: {
              label: 'Referencia de ubicación',
              placeholder: 'Seleccionar referencia',
            },
            stringField: {
              label: 'Detalle de referencia',
              placeholder: 'Detalle de referencia',
            },
            options: {
              nextTo: 'Junto a',
              opposite: 'Frente a',
              diagonal: 'Diagonal a',
              near: 'Cerca a',
            },
          },
        },
      },
      financialData: {
        title: 'Información financiera',
        subtitle:
          'Nos permitirá conocer que tarjeta es la adecuada para ti y que puedas disfrutar de todos los beneficios que te ofrecemos',
        description:
          'Analizamos ingresos mensuales provenientes de empleo, fuentes adicionales y recursos financieros. Asegúrate de incluir todas tus fuentes de ingresos para una evaluación precisa y acceder a las mejores opciones de tarjetas de crédito',
        fields: {
          bank: {
            label: 'Banco',
            placeholder: 'Elige tu banco',
          },
          accountNumber: {
            label: 'Número de cuenta',
            placeholder: 'Ingresa tu número de cuenta',
          },
          income: {
            label: 'Ingresa el monto de tus ingresos totales mensuales',
            placeholder: 'Ingresa el valor de tus ingresos',
          },
          incomeCertificates: {
            label: 'Justificación ingresos',
            placeholder:
              'Carga al menos un archivo que justifique tus ingresos',
            infoText: 'Carga al menos un documento que justifique tus ingresos',
            button: 'Aceptar',
            modalTitle: 'Respalda tus ingresos',
            loaded: 'Se han cargado ',
            files: ' archivos',
          },
          vehiclesValue: {
            label: 'Valor vehículos',
            placeholder:
              'Ingresa un valor aproximado de tus bienes en vehículos',
          },
          propertiesValue: {
            label: 'Valor viviendas',
            placeholder:
              'Ingresa un valor aproximado de tus viviendas y terrenos',
          },
        },
      },
      employmentData: {
        title: 'Información laboral',
        subtitle:
          'Detalla tu situación laboral para poder estudiar tu perfil crediticio y capacidad de pago de las tarjetas de crédito',
        fields: {
          modality: {
            label: 'Modalidad',
            options: {
              dependent: 'Dependiente',
              independent: 'Independiente',
            },
          },
          position: {
            label: 'Cargo',
            placeholder: 'Selecciona tu cargo actual',
          },
          company: {
            label: 'Empresa',
            placeholder: 'Ingresa el nombre de tu empresa',
          },
          companyCity: {
            label: 'Ciudad',
            placeholder: 'Selecciona tu ciudad',
          },
          companyPhone: {
            label: 'Teléfono oficina',
            placeholder: 'Ingresa el número de tu oficina',
          },
          companyMainStreet: {
            label: 'Calle principal',
            placeholder: 'Ingresa la calle principal',
          },
          companyNumber: {
            label: 'Numeración',
            placeholder: 'Ingresa la numeración',
          },
          companySecondStreet: {
            label: 'Calle secundaria',
            placeholder: 'Ingresa la calle secundaria',
          },
          locationRefCompany: {
            selectorField: {
              label: 'Referencia de ubicación',
            },
            stringField: {
              label: 'Detalle de referencia',
            },

            options: {
              nextTo: 'Junto a',
              opposite: 'Frente a',
              diagonal: 'Diagonal a',
              near: 'Cerca a',
            },
          },
          laborSeniority: {
            label: 'Antigüedad laboral',
            placeholder: 'Entre 1 y 4 meses',
            options: {
              between1and4: 'Entre 1 y 4 meses',
              more4less12: 'Más de 4 meses y menos de 12 meses',
              more12less24: 'Más de 12 meses y menos de 24 meses',
              morethan24: 'Más de 24 meses',
            },
          },
        },
      },
      declarations: {
        title: 'Declaratorias',
        subtitle:
          'Para cumplir con la legislación necesitamos que nos indiques donde resides y si eres una persona expuesta políticamente',
      },
      declarationOfTaxResidency: {
        title: 'Declaratoria de Residencia Fiscal',
        subtitle:
          'Confirmanos si tienes una residencia fiscal en otro(s) país(es) diferente(s) a Ecuador',
        fields: {
          confirmResidency: {
            options: ['No, solo en Ecuador', 'Si, en otro país'],
          },
          otherCountry: {
            placeholder: 'Ingrese el País',
            options: {
              nextTo: 'Junto a',
              opposite: 'Frente a',
              diagonal: 'Diagonal a',
              near: 'Cerca a',
            },
          },
        },
      },
      declarationPEP: {
        title: 'Persona Expuesta Políticamente',
        subtitle:
          'Declaro que soy una Persona Expuesta Políticamente por desempeñar o haber desempeñado alguna dignidad pública de elección popular o de alta responsabilidad en el estado',
        fields: {
          confirmPEP: {
            options: ['No lo soy', 'Si, lo soy'],
            infoText: [
              'Personas expuestas políticamente PEP: personas naturales nacionales o extranjeras, que desempeñan o han desempeñado funciones públicas destacadas en el país o en el extranjero en representación del país.',
              'Familiar de PEP: Cónyuges o personas unidas bajo el régimen de unión de hecho con un PEP, sus familiares hasta el segundo grado de consanguinidad o primero de afinidad.',
              'Colaborador cercano: son los asesores, consultores, colaboradores de trabajo de un PEP o que mantienen relación societaria, comercial o laboral.',
            ],
          },
        },
      },
    },
  },
  formThirdStep: {
    headerText:
      'Los siguientes datos nos ayudarán a generar tu tarjeta en caso de que sea aprobada:',
    selectNameSection: {
      title: 'Datos de la tarjeta',
      subtitle: 'Indícanos las preferencias de tu tarjeta',
    },
    fields: {
      selectCards: {
        annualCharge: 'Costo de tarjeta',
        zeroCost: 'Sin costo el primer año',
        chargeText: 'después',
        footerText: 'Saber más',
      },
      selectNameField: {
        label: 'Elige el nombre de la tarjeta',
        placeholder: 'Selecciona un nombre',
      },
      deliveryDateField: {
        label: 'Selecciona tu fecha preferida de pago',
        options: {
          end: 'El 15 de cada mes',
          middle: 'El 30 de cada mes',
        },
        infoTextFirst:
          'Fecha de corte, es el día en el cual se facturan los consumos y cargos efectuados en los últimos 30 días.',
        infoTextSecond:
          'Fecha de pago, se reflejará en tu estado de cuenta y corresponde a la fecha máxima o fecha límite de pago, en la que debes realizar el pago mínimo o total exigible para no incurrir en mora.',
      },
      deliveryLocField: {
        label: '¿Dónde quieres recibir tu tarjeta?',
        options: {
          home: 'Casa',
          work: 'Trabajo',
        },
      },
      deliveryReportField: {
        label: '¿Quieres recibir tu estado de cuenta de forma virtual?',
        options: {
          virtual: 'Solo virtual',
          material: 'Virtual y Físico',
        },
      },
      contactTimeField: {
        label:
          'En caso de requerirlo, ¿En qué horario preferirías que te contactemos?',
        options: {
          h8to10: '8h00 - 10h00',
          h10to12: '10h00 - 12h00',
          h12to14: '12h00 - 14h00',
          h14to16: '14h00 - 16h00',
          h16to18: '16h00 - 18h00',
          h18to20: '18h00 - 20h00',
        },
      },
    },
  },
  creditCardsFinalScreen: {
    footer: {
      button: 'Entendido',
      link: 'Imprimir',
    },
    successPrecalified: {
      info: {
        text:
          'Recibirás tu/s tarjeta/s de crédito en el transcurso de los próximos días',
      },
      title: {
        text: 'Solicitud Exitosa',
      },
      knowMore: {
        title: 'Complementa tus Tarjetas Diners Club',
        description:
          'Comparte tu tarjeta con los que más quieres y disfruta de beneficios exclusivos que tenemos para ti.',
        footer: {
          subtitle:
            'Descubre todas las tarjetas complementarias que tiene Diners Club para ti.',
          textButton: 'Conoce más',
        },
      },
      urls: {
        moreInfo: 'Descubre más beneficios en:',
        dinersURL: 'www.dinersclub.com.ec',
        href:
          'https://www.dinersclub.com.ec/tarjetas/diners-club/otras-tarjetas',
      },
    },
    successUnprecalified: {
      info: {
        text: 'Pronto uno de nuestros asesores se pondrá en contacto contigo.',
      },
      title: {
        text: 'Solicitud Exitosa',
      },
      urls: {
        moreInfo: 'Descubre más beneficios en:',
        dinersURL: 'www.dinersclub.com.ec',
        titaniumURL: 'www.titanium.com.ec',
        discoverURL: 'www.discover.ec',
      },
    },
    unsuccess: {
      info: {
        text:
          'Al momento no puedes continuar con la petición de tarjeta a través de este portal',
      },
      title: {
        text: 'Gracias por tu interés',
      },
    },
  },
  errorList: {
    default: 'Ha ocurrido un error.',
    wrongData: 'Por favor, revisa los datos ingresados.',
    thanks: 'Gracias por tu interés.',
    communication: 'Ha ocurrido un error de comunicación.',
    infoClient:
      'Estamos analizando tu información, pronto nos pondremos en contacto contigo.',
    contactWithClient:
      'Un asesor se pondrá en contacto contigo en el transcurso de 24 horas.',
    contactWithCallCenter:
      'Por favor, comunícate con nuestro Call Center al (02)\u00a02984-400.',
    memberWithCard: 'Al momento ya cuentas con una tarjeta.',
    registeredUser: 'Al momento ya cuentas con una solicitud en espera.',
    shippingAddress:
      'No podemos realizar entregas a ninguna de las direcciones proporcionadas.',
    locationError:
      'Se ha producido un error en la geolocalización. Por favor, revise las direcciones introducidas.',
    invalidFacialIdentity:
      'Disculpa, tu rostro no corresponde con el de nuestro sistema.',
    filesError: 'Al momento no podemos otorgarte una tarjeta.',
    expiredToken:
      'El token de sesión ha expirado. Prueba nuevamente en unos instantes.',
  },
  summaryDataScreen: {
    title: 'Validación de identidad',
    subtitle:
      'La validación de tu identidad es necesaria para tu protección. Asegura tu solicitud y recibe tu tarjeta sin preocupaciones',
    secondSubtitle: '¿Cómo funciona?',
    howItWorks: {
      step1: {
        title: 'Recibe el enlace',
        subtitle:
          'Te enviaremos un enlace a tu <strong>correo electrónico</strong> y <strong>celular</strong>.',
      },
      step2: {
        title: 'Abre el correo o el mensaje',
        subtitle:
          'Abrelo en un dispositivo con cámara, idealmente tu teléfono.',
      },
      step3: {
        title: 'Captura tu foto',
        subtitle:
          'Abrelo en un dispositivo con cámara, idealmente tu teléfono.',
      },
      step4: {
        title: 'Finaliza el proceso',
        subtitle: 'Recibirás una confirmación con los próximos pasos a seguir.',
      },
    },
    titleSectionSelected: 'Productos Seleccionados:',
    categoryName: 'Nombre titular',
    categoryCity: 'Ciudad',
    categoryAddress: 'Dirección',
    categoryCard: 'Tarjetas solicitadas',
    categoryCardLocation: 'Lugar de entrega',
    linkLabel: '¿No recibiste el enlace?',
    link: 'Enviar nuevamente',
    resendTitle: 'Reenviar enlace',
    resendSubtitle: 'Reenviar enlace a tu celular',
    phoneLabel: 'Celular',
    phonePlaceholder: 'Ingresa tu número de celular',
    sendLink: 'Enviar enlace',
    sendingLink: 'Enviando Enlace',
    linkSended: 'Enlace Enviado',
    buttonText: 'Continuar',
    sendMessageInfo:
      'Hemos enviado el enlace a tu correo electrónico y celular. Por favor, continúa tu solicitud a través del mismo.',
    phonesNotMatch:
      'El número de teléfono no corresponde al que ingresaste cuando iniciaste este proceso. Si deseas continuar con un nuevo número, este será registrado en nuestros sistemas como tu único número de contacto. ¿Deseas continuar?',
    productSelected: 'Producto(s) seleccionado(s)',
    codeSendTitle: '¡Enviamos tu Enlace!',
    codeSendText:
      'Hemos enviado el enlace a tu correo electrónico. Este proceso puede tardar hasta un minuto. Si no lo recibes, simplemente agrega tu número de teléfono nuevamente para enviártelo de inmediato',
  },
  others: 'Otros',
  error404: {
    title: 'ALGO ANDA MAL',
    subtitle:
      'La página solicitada no existe. Puedes regresar a la página principal.',
    button: 'PÁGINA PRINCIPAL',
  },
  prueba: {
    prueba: 'esto es una prueba',
  },
  infoCard: {
    subtitle: 'Los Beneficios de tu mundo sin Límites',
    shortDescription:
      'La unica Tarjeta de Credito sin un cupo preestablecido de gastos, perfecta para ser utilizada cada dia, con el',
    textMore: {
      mobile: 'Saber más',
      desktop: 'Ver beneficios',
    },
    textButton: {
      select: 'Seleccionar',
      unselect: 'Deseleccionar',
    },
  },
  prepareToVerify: {
    title: 'Verificación de Identidad',
    subtitle:
      'Para tu seguridad, necesitamos tu fotografía para verificar tu identidad. Asegúrate de contar con buena iluminación y sigue estos pasos:',
    secondSubtitle: '¿Cómo funciona?',
    howItWorks: {
      step1: {
        title: 'Autoriza la cámara',
        subtitle: 'Concede los permisos de cámara para continuar',
      },
      step2: {
        title: 'Sin obstrucciones',
        subtitle:
          'Elimina artículos que puedan obstruir tu rostro, como <strong>gafas</strong>, <strong>sombreros</strong> o <strong>audífonos</strong>.',
      },
      step3: {
        title: 'Centra tu rostro',
        subtitle: 'Posiciona tu rostro claramente en el centro de la pantalla.',
      },
      step4: {
        title: 'Captura y envía',
        subtitle: 'Sigue las instrucciones para capturar y enviar tu foto.',
      },
    },
    buttonText: 'Validar',
  },
}

export default esTranslation
