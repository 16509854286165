import { get, post } from '@Services/api'
import { SelectOptionType } from '@Types/FieldTypes'

export type apiOptionType = { id: number; name: string }
type apiDataResponse<T> = { data: T[] }
export type apiFacialUidData = {
  facialUid: string
  facialIdentificationStatus: string
}
export type apiFacialUidDataResponse = {
  data: apiFacialUidData
}

export type ApiCredentials = {
  data: { access: string; refresh: string }
}

export type ApiRetargetingResponse = {
  data: {
    formToken: string
    previousStep: number
    previousStepResponse: any
    extraData: any
  }
}

export const stringToTitleCase = (element: string) => {
  let convertedString: string = element
  convertedString = convertedString
    .toLowerCase()
    .split(' ')
    .map(s => {
      if (s === 'de') {
        return s
      }
      return s.charAt(0).toUpperCase() + s.substring(1)
    })
    .join(' ')

  return convertedString
}

export const getDataResponse = (data: apiOptionType[]) => {
  return data.map((res: apiOptionType) => ({
    id: res.id,
    value: stringToTitleCase(res.name),
  }))
}

export const getCredentials = () => {
  return post('/api/token', {}).then((response: ApiCredentials) => {
    return response
  })
}

export const loadData = <T>(
  data: 'cities' | 'job_titles' | 'banks' | 'countries',
  apiToken: string,
) => {
  return get('/' + data + '/', {
    headers: [{ id: 'api-token', value: apiToken }],
  }).then((response: apiDataResponse<T>) => response.data)
}

export const loadDistricts = (
  homeAddress: SelectOptionType,
  apiToken: string,
) => {
  return get('/districts/?city=' + homeAddress.id, {
    headers: [{ id: 'api-token', value: apiToken }],
  }).then((response: apiDataResponse<apiOptionType>) =>
    getDataResponse(response.data),
  )
}

export const postCreditCards = (
  tokenForm: string,
  type: 'sign_up/end' | 'verify_identity',
  apiToken: string,
) => {
  return post(
    '/credit_cards/' + type,
    {},
    {
      headers: [
        { id: 'Token-Form', value: tokenForm },
        { id: 'api-token', value: apiToken },
      ],
    },
  )
}

export const loadRetargetingData = (
  retargetingToken: string,
  apiToken: string,
) => {
  return post(
    '/credit_cards/retargeting',
    {
      retargeting: retargetingToken,
    },
    {
      headers: [{ id: 'api-token', value: apiToken }],
    },
  )
}
