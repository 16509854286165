import APIDataContext from '@Contexts/APIDataContext'
import CreditCardFlowStepsContext from '@Contexts/CreditCardFlowStepsContext'
import i18n from '@I18nConfig'
import { Error404 } from '@Screens/Error404'
import {
  CampaingType,
  SelectCardOptionType,
  SelectOptionType,
} from '@Types/FieldTypes'
import {
  BankModel,
  CityModel,
  CountryModel,
  FormValues,
} from '@Types/FormDataTypes'
import { getCookie, loadTokenIfExpired, setCookie } from '@Utils/Cookies'
import history from '@Utils/History'
import {
  ApiCredentials,
  getCredentials,
  stringToTitleCase,
  loadData,
  loadDistricts,
  getDataResponse,
  apiOptionType,
} from '@Utils/loadDataFunctions'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import {
  Redirect,
  Route,
  Router as ReactRouter,
  Switch,
} from 'react-router-dom'
import RedirectToNewLocation from './RedirectToNewLocation'
import PrepareToVerify from '@Screens/PrepareToVerify'
import RetargetingContext from '@Contexts/RetargetingContext'

const CreditCardForm = lazy(() => import('@Components/CreditCardForm'))
const Layout = lazy(() => import('@Components/Layout'))
const IdentifyUser = lazy(() => import('@Screens/IdentifyUser'))
const SummaryData = lazy(() => import('@Screens/SummaryData'))

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string)

const Router = () => {
  const [displaySummaryData, setDisplaySummaryData] = useState(false)
  const [isPrecalified, setIsPrecalified] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<FormValues>({})
  const [tokenForm, setTokenForm] = useState('')
  const [cities, setCities] = useState<CityModel[]>([])
  const [countries, setCountries] = useState<CountryModel[]>([])
  const [positions, setPositions] = useState<SelectOptionType[]>([])
  const [banks, setBanks] = useState<BankModel[]>([])
  const [districts, setDistricts] = useState<SelectOptionType[]>([])
  const [city, setCity] = useState<SelectOptionType>()
  const [cookieState, setCookieState] = useState<string>()
  const [campaing, setCampaing] = useState<CampaingType>()
  const [productsSelected, setProductsSelected] = useState<
    SelectCardOptionType[]
  >([])
  const [datosRetargeting, setDatosRetargeting] = useState({
    token: '',
    currentStep: '',
    extraData: {},
    previousStepResponse: {},
  })
  const actualizarDatosRetargeting = (
    nuevosDatos: React.SetStateAction<{
      token: string
      currentStep: string
      extraData: any
      previousStepResponse: any
    }>,
  ) => {
    setDatosRetargeting(nuevosDatos)
  }

  useEffect(() => {
    getCredentials().then((response: ApiCredentials) => {
      setCookie('api-token', response.data.access)
      setCookieState(response.data.access)
    })
  }, [])

  useEffect(() => {
    if (getCookie('api-token') !== null) {
      //eslint-disable-next-line
      loadData('cities', getCookie('api-token') as string)
        .then((response: CityModel[]) =>
          setCities(
            response.map((res: any) => ({
              id: res.id,
              value: stringToTitleCase(res.name),
              verifiable: res.verifiable,
            })),
          ),
        )
        .catch((errorResponse: any) => loadTokenIfExpired(errorResponse))
      loadData('countries', getCookie('api-token') as string)
        .then((response: CountryModel[]) =>
          setCountries(
            response.map((res: any) => ({
              id: res.id,
              value: stringToTitleCase(res.name),
              ISO3: res.ISO3,
              ISO2: res.ISO2,
            })),
          ),
        )
        .catch((errorResponse: any) => loadTokenIfExpired(errorResponse))
      loadData('job_titles', getCookie('api-token') as string)
        .then((response: apiOptionType[]) =>
          setPositions(getDataResponse(response)),
        ) // eslint-disable-next-line
        .catch((errorResponse: any) => loadTokenIfExpired(errorResponse))
      loadData('banks', getCookie('api-token') as string)
        .then((response: BankModel[]) => {
          // eslint-disable-next-line
          setBanks(response.map((res: any) => ({
              id: res.id,
              value: stringToTitleCase(res.name),
              verifiable: res.verifiable,
            })),
          )
        }) // eslint-disable-next-line
        .catch((errorResponse: any) => loadTokenIfExpired(errorResponse))
    }
  }, [cookieState])

  useEffect(() => {
    if (city)
      loadDistricts(city, getCookie('api-token') as string)
        .then((response: SelectOptionType[]) => {
          setDistricts(response.concat({ id: 0, value: i18n.t('others') }))
        }) // eslint-disable-next-line
        .catch((errorResponse: any) => loadTokenIfExpired(errorResponse))
    else {
      if (districts.length !== 0) setDistricts([])
    } // eslint-disable-next-line
  }, [city, getCookie('api-token')])

  useEffect(() => {
    history.listen(location => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })
    ReactGA.pageview(window.location.pathname + window.location.search)
    let query: string | null = getCookie('campaing')
    if (window.location.search.includes('utm_')) {
      query = window.location.search
    }

    if (!!query) {
      setCookie('campaing', query)
      const search = new URLSearchParams(query)
      const iterator = search.keys()
      let result = iterator.next()
      const params: any = {}

      while (!result.done) {
        if (result.value.includes('utm_')) {
          params[result.value] = search.get(result.value)
        }
        result = iterator.next()
      }
      setCampaing(params)
    }
  }, [])

  return (
    <RetargetingContext.Provider
      value={{ datosRetargeting, actualizarDatosRetargeting }}
    >
      <Suspense fallback={() => <p>Loading</p>}>
        <APIDataContext.Provider
          value={{
            cities: cities,
            countries: countries,
            positions: positions,
            banks: banks,
            districts: districts,
            city: city,
            setCity: setCity,
            campaing: campaing,
          }}
        >
          <CreditCardFlowStepsContext.Provider
            value={{
              displaySummaryData: displaySummaryData,
              setDisplaySummaryData: setDisplaySummaryData,
              isPrecalified: isPrecalified,
              setIsPrecalified: setIsPrecalified,
              formValues: formValues,
              setFormValues: setFormValues,
              tokenForm: tokenForm,
              setTokenForm: setTokenForm,
              productsSelected: productsSelected,
              setProductsSelected: setProductsSelected,
            }}
          >
            <ReactRouter history={history}>
              <Layout>
                <Switch>
                  <Route exact path="/identify-user/:uid([0-9a-z]{32})">
                    <IdentifyUser />
                  </Route>
                  <Route
                    exact
                    path="/tarjetas-credito/form"
                    component={CreditCardForm}
                  />
                  <Route exact path="/tarjetas-credito/summary-data">
                    <SummaryData
                      formData={formValues}
                      isPrecalified={isPrecalified}
                    />
                  </Route>
                  <Route
                    path="/tarjetas-credito"
                    render={() => (
                      <RedirectToNewLocation newRoute="/tarjetas-credito/form" />
                    )}
                  />
                  <Route exact path="/">
                    <Redirect to="/tarjetas-credito" />
                  </Route>
                  <Route component={Error404} />
                </Switch>
              </Layout>
            </ReactRouter>
          </CreditCardFlowStepsContext.Provider>
        </APIDataContext.Provider>
      </Suspense>
    </RetargetingContext.Provider>
  )
}

export default Router
