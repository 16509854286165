import { CampaingType, SelectOptionType } from '@Types/FieldTypes'
import { BankModel, CityModel, CountryModel } from '@Types/FormDataTypes'
import { createContext } from 'react'

type APIDataContextType = {
  cities: CityModel[]
  countries: CountryModel[]
  positions: SelectOptionType[]
  banks: BankModel[]
  districts: SelectOptionType[]
  city?: SelectOptionType
  setCity: React.Dispatch<React.SetStateAction<SelectOptionType | undefined>>
  campaing?: CampaingType
}

const APIDataContext = createContext({
  cities: [],
  countries: [],
  positions: [],
  banks: [],
  districts: [], //eslint-disable-next-line
  setCity: () => {}
} as APIDataContextType)

export default APIDataContext
