import i18n from '@I18nConfig'
import { ErrorGrid, ErrorText } from '@StyledComponents/Error/Error'
import React from 'react'

const Error404Title = () => {
  return (
    <ErrorGrid item xs={12} className="text">
      <ErrorText className="title">{i18n.t('error404.title')}</ErrorText>
    </ErrorGrid>
  )
}

export default Error404Title
