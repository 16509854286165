export const colors = {
  primary: '#4C71FC',
  cottonBall: '#F0F3FF',
  blackRiver: '#344054',
  greylight: '#D9D9D9',
  drWhite: '#F9FAFB',
  snowFlake: '#F0F0F0',
  text: '#6d7278',
  grey: '#808080',
  mercury: '#EBEBEB',
  darkText: '#4a4a4a',
  forgedSteel: '#5A5A5A',
  carbon: '#333333',
  dynamicBlack: '#1E1E1E',
  soap: '#44aaff',
  inputBorder: '#E7E7E7',
  aliceblue: '#ecf0ff',
  disabledButton: 'rgba(76, 113, 252, 0.5)',
  silver: '#99a0bc',
  error: '#ff4654',
  errorLight: '#f9828b',
  errorBackground: '#ffeded',
  disabledInputBorder: 'rgba(0, 0, 0, 0.29)',
  disabledInputText: '#676666',
  disabledInputBackground: '#ECECEC',
  white: '#FFFFFF',
  shadow: '#dcdcdc',
  homeSubtitle: '#4c4c4c',
  homeTitle: '#133d77',
  homeGradient: {
    first: '#396AF6',
    second: '#6E91F9',
    third: '#809FFA',
  },
  placeholder: '#9B9B9B',
  horizontalLine: '#e4e4e8',
}

export const fonts = {
  primary: 'Work Sans',
  primaryRegular: 'Work Sans, Regular',
  primaryMedium: 'Work Sans, Medium',
}
