import { createContext } from 'react'

type RetargetingContextType = {
  datosRetargeting: {
    token: string
    currentStep: string
    extraData: any
    previousStepResponse: any
  }
  actualizarDatosRetargeting: (nuevosDatos: any) => void
}

const RetargetingContext = createContext({
  datosRetargeting: {
    token: '',
    currentStep: '',
    extraData: {},
    previousStepResponse: {},
  },
} as RetargetingContextType)

export default RetargetingContext
