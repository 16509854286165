import { Button, Grid, Typography } from '@material-ui/core'
import { colors, fonts } from '@StyledComponents/theme'
import styled from 'styled-components'

export const ErrorText = styled(Typography)`
  font-family: ${fonts.primary};
  &.title {
    font-size: 20px;
    font-weight: bold;
    color: ${colors.primary};
  }

  &.subtitle {
    font-size: 14px;
  }
`

export const ErrorButton = styled(Button)`
  background-color: ${colors.primary};
  border-color: ${colors.primary};
  border-radius: 30px;
  color: white;
  font-family: ${fonts.primaryRegular};

  box-shadow: 0px 1px ${colors.shadow};

  .MuiButton-label {
    margin: 5px 50px 5px 50px;
  }

  &:hover {
    background-color: ${colors.soap};
  }
`
export const ErrorGrid = styled(Grid)`
  text-align: center;

  &.text {
    padding-top: 30px;
  }

  &.button {
    padding-top: 50px;
  }
`
export const ErrorImage = styled.img`
  @media (max-width: 569px) {
    width: 40vw;
  }
`
