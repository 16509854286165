import error404 from '@Assets/images/404.png'
import { ErrorGrid, ErrorImage } from '@StyledComponents/Error/Error'
import React from 'react'

const Error404Image = () => {
  return (
    <ErrorGrid item xs={12}>
      <ErrorImage src={error404} alt="error-404" />
    </ErrorGrid>
  )
}

export default Error404Image
